<template>
  <div class="days-table">
    <div class="days-table__heading">
      <div class="left-col">
        <span class="date fz12 fw500 text-gray-text">{{
          date | formatDate
        }}</span>
      </div>
      <div class="right-col">
        <div
          v-if="
            taskstsLoaded &&
            tasks.length != 0 &&
            categoriesFilters.tasks &&
            getPermissionsByType('can_show_tasks_in_the_calendar')
          "
          class="tasks-wrapper"
        >
          <div
            class="tasks-wrapper__heading fz13 fw500"
            :class="{ showTasks: showAllTasks }"
            @click="showAllTasks = !showAllTasks"
          >
            <span class="arrow" v-if="showAllTasks">
              <svg
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.27557 4.00288C7.54284 4.00288 7.67669 3.67974 7.4877 3.49075L4.21196 0.215017C4.0948 0.0978596 3.90485 0.0978593 3.7877 0.215017L0.511973 3.49075C0.322984 3.67974 0.456834 4.00288 0.724105 4.00288L7.27557 4.00288Z"
                  fill="#2C2C2C"
                />
              </svg>
            </span>
            <span class="arrow" v-else>
              <svg
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.764957 0.697845C0.497685 0.697845 0.363835 1.02099 0.552825 1.20998L3.82857 4.48571C3.94573 4.60287 4.13568 4.60287 4.25283 4.48571L7.52856 1.20998C7.71755 1.02099 7.5837 0.697845 7.31643 0.697845H0.764957Z"
                  fill="#2C2C2C"
                />
              </svg>
            </span>
            Tasks ({{ tasks.length }})
          </div>
          <div class="tasks-wrapper__content">
            <div
              class="task-block"
              v-for="(task, index) in tasks"
              :key="index"
              @click="$refs.taskInfoModal.showModal(task)"
            >
              <p class="task-block__title fz13 fw500">{{ task.name }}</p>

              <p class="task-block__name fz13 no-plus">
                {{getExecutors(task)}}
              </p>
              <p class="task-block__prioity fz13">
                Prioity:
                <span
                  class="prioity"
                  :class="{
                    Urgent: task.priority == 1,
                    high: task.priority == 2,
                    Normal: task.priority == 3,
                    Low: task.priority == 4,
                    Low: task.priority == 5,
                  }"
                >
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.85528 1.28944C9.92177 1.15646 9.82507 1 9.67639 1H1.5V0.75C1.5 0.335786 1.16421 0 0.75 0C0.335786 0 0 0.335786 0 0.75V11.25C0 11.6642 0.335786 12 0.75 12C1.16421 12 1.5 11.6642 1.5 11.25V9H9.67639C9.82507 9 9.92177 8.84354 9.85528 8.71056L8 5L9.85528 1.28944Z"
                      fill="#FF9035"
                    />
                  </svg>
                  <span v-if="task.priority == 1">Urgent</span>
                  <span v-if="task.priority == 2">high</span>
                  <span v-if="task.priority == 3">Normal</span>
                  <span v-if="task.priority == 4">Low</span>
                  <span v-if="task.priority == 5">Low</span>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="
            paymentsLoaded &&
            payments.results.length != 0 &&
            categoriesFilters.payments &&
            getPermissionsByType('can_show_payments_in_the_calendar')
          "
          class="payments-wrapper"
        >
          <div
            class="payments-wrapper__heading fz13 fw500"
            :class="{ showPayments: showAllPayments }"
            @click="showAllPayments = !showAllPayments"
          >
            <span class="arrow" v-if="showAllPayments">
              <svg
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.27557 4.00288C7.54284 4.00288 7.67669 3.67974 7.4877 3.49075L4.21196 0.215017C4.0948 0.0978596 3.90485 0.0978593 3.7877 0.215017L0.511973 3.49075C0.322984 3.67974 0.456834 4.00288 0.724105 4.00288L7.27557 4.00288Z"
                  fill="#2C2C2C"
                />
              </svg>
            </span>
            <span class="arrow" v-else>
              <svg
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.764957 0.697845C0.497685 0.697845 0.363835 1.02099 0.552825 1.20998L3.82857 4.48571C3.94573 4.60287 4.13568 4.60287 4.25283 4.48571L7.52856 1.20998C7.71755 1.02099 7.5837 0.697845 7.31643 0.697845H0.764957Z"
                  fill="#2C2C2C"
                />
              </svg>
            </span>
            Payments ({{ payments.results.length }})
          </div>
          <div class="payments-wrapper__content">
            <div
              class="payment-block"
              :class="{
                expired: getExpiredPayment(payment),
                payment: payment.payment_type == 'expense',
                invoice: payment.payment_type == 'income',
                paid: payment.status == 'paid',
                not_paid: payment.status == 'not_paid',
              }"
              @click="$router.push(`/payment/list/detail/${payment.id}`)"
              v-for="(payment, index) in payments.results"
              :key="index"
            >
              <div class="payment-block__name fz13">
                <span>{{ payment.id }}: </span>
                <span>{{ payment.account_name || "-" }}</span>
              </div>
              <p class="payment-block__status fz13">
                {{ payment.client_name || "-" }}
              </p>
              <p class="payment-block__num fz13 fw500">
                {{ payment.payment_amount }} {{ payment.currency }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hours-list">
      <div class="time-line" v-if="timeLineIsVisible" :style="{ top: time + 'px' }" ></div>
      <div class="hour" v-for="hour in 24" :key="hour">
        <div class="left-col"></div>
        <div
          class="right-col"
          :class="{ hideNum: hour == 24 }"
          :data-num="hour"
        ></div>
      </div>
      <div
        class="events-wrapper"
        v-if="eventsLoaded && categoriesFilters.events"
      >
        <div v-for="(event, index) in getEventsStyled" :key="index">
          <!-- height: `${event.height < 30 ? 30 : event.height }px`, -->
          <div
            class="event-block"
            :class="{ 'flex-row': event.height <= 30 }"
            :title="event | getTitle"
            :style="{
              top: `${event.top}px`,
              height: `${event.height }px`,
              width: `${100 / getEventsStyled.length}%`,
              left: `${(100 / getEventsStyled.length)*index}%` || null,
            }"
            @click="$refs.eventInfoModal.showModal(event, {date: date})"
          >
            <p class="fz13 fw500">{{ event.name }}</p>
            <p class="fz13" v-if="event.creatorInfo">
              {{ event.creatorInfo.first_name || "" }}
              {{ event.creatorInfo.last_name || "" }}
            </p>
            <p class="fz13">{{ event.from_time | formatTime }} - {{ event.to_time | formatTime}}</p>
          </div>
        </div>
      </div>
    </div>
    <taskInfoModal ref="taskInfoModal"></taskInfoModal>
    <eventInfoModal
      @delEvent="deleteEvent($event)"
      ref="eventInfoModal"
    ></eventInfoModal>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";
import taskInfoModal from "@/components/calendar/taskInfoModal";
import eventInfoModal from "@/components/calendar/eventInfoModal";
export default {
  components: { taskInfoModal, eventInfoModal },
  data: () => {
    return {
      date: "",
      time: "",
      eventsLoaded: false,
      taskstsLoaded: false,
      paymentsLoaded: false,
      showAllTasks: false,
      showAllPayments: false,
      events: [],
      tasks: [],
      tasksResponse: null,
      payments: [],
      allMembersList: null,
      categoriesFilters: {
        payments: true,
        tasks: true,
        events: true,
      },
    };
  },
  methods: {
    ...mapMutations(["showLoad", "hideLoad"]),
    ...mapActions([
      "axiosGetCalendarEventsList",
      "axiosGetCalendarTasksList",
      "axiosGetCalendarPaymentsList",
      "axiosGetEmployeeById",
      "axiosDeleteCalendarEvent",
    ]),
    ...mapGetters([
      "getCalendarEventsList",
      "getCalendarTasksList",
      "getCalendarPaymentsList",
      "getCalendarCurrentDate",
      "getCalendarCategoriesFilter",
      "getCalendarMembersList",
      "getCalendarMemberById"
    ]),
    isExcludeDate(event){
      let dateFormatting = moment(this.date).format("YYYY.MM.DD").split(".").join("-");
      if(!event.exclude_days){
        return true;
      }else{
        if(event.exclude_days.includes(dateFormatting)){
          return false;
        }else{
          return true;
        }
      }
    },
    async getEvents(filters = {}) {
      let events = await this.axiosGetCalendarEventsList(filters);
      let dayName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][new Date(this.date).getDay()]
      this.events = JSON.parse(JSON.stringify(events)).filter(event => {
        if(event.repeat == 'daily' && this.startDate(this.date, event.date) && this.isExcludeDate(event)){
          return event
        }else if(event.repeat == 'every_workday_repeat' && !['Sun', 'Sat'].includes(dayName) && this.startDate(this.date, event.date) && this.isExcludeDate(event)){
          return event;
        }else if(event.repeat == 'do_no_repeat' && this.dateMatches(event.date, this.date)){
          return event;
        }else if(event.repeat == 'custom_days_repeat' && this.startDate(this.date, event.date)){
          if(Array.isArray(event.days_for_repeat) && event.days_for_repeat.length){
            if(event.days_for_repeat.includes(this.date) || this.dateMatches(event.date, this.date)) return event
          }
        }
      });
      this.eventsLoaded = true;

      // Показать событие если перешел с нотификаций
      if(this.$route.query.eventId){
        let event = this.events.find(el => el.id == this.$route.query.eventId)
        this.$refs.eventInfoModal.showModal(event, {date: event.date})
        this.$router.replace({'query': null});
      }
    },
    dateMatches(date1, date2){
      if(new Date(date1).toLocaleDateString("en-US") == new Date(date2).toLocaleDateString("en-US")){
        return true;
      }else{
        return false;
      }
    },
    // Определить дату начала повторяющихся событий события
    startDate(currentDate, createdDate){
      if(moment(currentDate) >= moment(createdDate)){
        return true;
      }else{
        return false;
      }
    },
    async getTasks(filter = {}) {
      // TODO Удалить лишние таски и настроить фильтр
      let dayName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][new Date(this.date).getDay()]
      this.tasksResponse = await this.axiosGetCalendarTasksList(filter);
      
      this.tasks = JSON.parse(JSON.stringify(this.tasksResponse.results)).filter(task => {
        if(task.due_date_status == 'every_workday_repeat' && !['Sun', 'Sat'].includes(dayName) && this.startDate(this.date, task.created_at.split(' ')[0])){
          return task;
        }else if(task.due_date_status == 'every_day_repeat' && this.startDate(this.date, task.created_at.split(' ')[0])){
          return task;
        }else if(task.due_date_status == 'due_date' || task.due_date_status == 'custom_days_repeat'){
          return task;
        }
      })
      this.taskstsLoaded = true;
    },
    getExecutors(task){
      if(Array.isArray(task.executors) && task.executors.length){
        let member = this.getCalendarMembersList().results.find(el => el.id == task.executors[0]);
        if(task.executors.length > 1){
          if(member){
            return `${member.name} (+${task.executors.length - 1})`
          }
        }else{
          return `${member.name}`
        }
      }
      return `-`
      
    },
    async getPayments(filter = {}) {
      let payments = await this.axiosGetCalendarPaymentsList(filter);
      this.payments = payments;
      this.paymentsLoaded = true;
      return payments;
    },
    getExpiredPayment(payment) {
      return (
        new Date(this.date).getTime() > new Date(payment.paid_date).getTime()
      );
    },
    async getAllData() {
      this.showLoad();
      await this.getEvents({
        date_after: this.date,
        date_before: this.date,
      });
      await this.getTasks({
        date_after: this.date,
        date_before: this.date,
        // limit: 5,
        is_archived: false,
        // only_for_me: true
      });
      await this.getPayments({
        due_date_after: this.date,
        due_date_before: this.date,
        // ship_null: true,
        is_archive: false
      });
      this.hideLoad();
    },
    deleteEvent(id) {
      this.axiosDeleteCalendarEvent(id)
        .then(async () => {
          this.showLoad();
          await this.getEvents({
            date_after: this.date,
            date_before: this.date,
          });
          this.hideLoad();
          // this.$myEmitter.emit("lodadNewData", this.date);
          // this.$myEmitter.emit("UpdateEvents", this.date);
        })
        .catch(async () => {
          this.showLoad();
          await this.getEvents({
            date_after: this.date,
            date_before: this.date,
          });
          this.hideLoad();
        });
    },
  },
  computed: {
    ...mapGetters(['getPermissionsByType']),
    getEventsStyled(){
      let events = this.events;
      events.forEach(async (el) => {
        let from_time = el.from_time.split(":");
        let to_time = el.to_time.split(":");
        el.top = parseInt(from_time[0]) * 60 + parseInt(from_time[1]);
        el.height =
          parseInt(to_time[0]) * 60 +
          parseInt(to_time[1]) -
          (parseInt(from_time[0]) * 60 + parseInt(from_time[1]));
        el.creatorInfo = await this.getCalendarMembersList().results.find(element => element.id == el.creator);
      });

      events.sort((a, b) => {
        if (b.height > a.height) {
          return 1;
        } else {
          return -1;
        }
      });
      return events;
    },
    timeLineIsVisible(){
      let dateNow = moment().format('YYYY-MM-DD')
      return moment(dateNow).isSame(this.date)
    }
  },
  filters: {
    getTitle: function (value) {
      let membersNames = [];
      let string;
      if (value.members.length != 0) {
        value.members.forEach((el) => {
          membersNames.push(`${el.member.first_name} ${el.member.last_name}`);
        });
        string = `${value.name}\n\n${membersNames.join("\n")}\n\n${
          value.from_time
        } - ${value.to_time}`;
      } else {
        string = `${value.name}\n${value.from_time} - ${value.to_time}`;
      }
      return string;
    },
    formatDate: function(value){
      return moment(value).format('MMM, D').replace(/[\s.,%]/g, ' ');
    },
    formatTime: function(value){
      return moment(value, "HH:mm:ss").format('HH:mm');
    }
  },
  async mounted() {
    let date = new Date();
    this.date = moment(date).format("YYYY.MM.DD").split(".").join("-");
    this.time = date.getHours() * 60 + date.getMinutes();
    // Time line
    setInterval(() => {
      this.time++;
    }, 60000);
  },
  activated() {
    
    this.categoriesFilters = this.getCalendarCategoriesFilter();
    if (this.getCalendarCurrentDate()) {
      this.date = moment(this.getCalendarCurrentDate())
        .format("YYYY.MM.DD")
        .split(".")
        .join("-");
    }
    this.getAllData();
    this.$myEmitter.on("setCategoryFilter", () => {
      this.categoriesFilters = this.getCalendarCategoriesFilter();
    });
    this.$myEmitter.on("lodadNewData", (date) => {
      this.date = moment(date).format("YYYY.MM.DD").split(".").join("-");
      this.getAllData();
    });
    this.$myEmitter.on("loadFilteredEvents", async () => {
      this.showLoad();
      await this.getEvents({
        date_after: this.date,
        date_before: this.date,
      });
      this.hideLoad();
    });
    this.$myEmitter.on("UpdateEvents", async (date) => {
      this.date = moment(date).format("YYYY.MM.DD").split(".").join("-");
      this.showLoad();
      await this.getEvents({
        date_after: this.date,
        date_before: this.date,
      });
      this.hideLoad();
    });
  },
  deactivated() {
    this.events = [];
    this.eventsLoaded = false
    this.$myEmitter.off("lodadNewData");
    this.$myEmitter.off("UpdateEvents");
    this.$myEmitter.off("loadFilteredEvents");
    this.$myEmitter.off("setCategoryFilter");
  },
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.days-table {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.days-table__heading {
  width: 100%;
  // height: 64px;
  border-bottom: 1px solid var(--color-card-border);
  display: flex;
  z-index: 1;
  .left-col {
    width: 53px;
    min-width: 53px;
    border-right: 1px solid var(--color-card-border);
    padding: 6px 8px;
    box-sizing: border-box;
  }
  .right-col {
    width: 95%;
    padding: 1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .task-block {
      background: #ffedc3;
      flex-grow: 1;
      border: 1px solid #fff;
      padding: 4px 9px;
      display: flex;
      flex-direction: column;
      .task-block__title,
      .task-block__name,
      .task-block__prioity {
        color: #785400;
        padding-bottom: 3px;
        display: flex;
        align-items: center;
        span {
          display: flex;
          align-items: center;
          margin-left: 5px;
          svg {
            margin-right: 5px;
          }
          &.High {
            svg {
              path {
                fill: #f2994a;
              }
            }
          }
          &.Urgent {
            svg {
              path {
                fill: #e85353;
              }
            }
          }
          &.Normal {
            svg {
              path {
                fill: #0fa54e;
              }
            }
          }
          &.Low {
            svg {
              path {
                fill: #9aa3b0;
              }
            }
          }
        }
      }
      .task-block__name:not(.no-plus):after {
        content: "+" attr(data-num);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 3px;
        border-radius: 50%;
        border: 1px solid #785400;
        margin-left: 5px;
        font-size: 11px;
        line-height: 13px;
      }
    }
    .payment-block {
      background: #f4f6f9;
      flex-grow: 1;
      border: 1px solid #fff;
      border-left: 3px solid #f4f6f9;
      padding: 4px 9px;
      display: flex;
      flex-direction: column;
      height: 64px;
      justify-content: center;
      .payment-block__status,
      .payment-block__num {
        margin-bottom: 4px;
        // display: flex;
        // align-items: center;
        display: inline;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        padding-left: 18px;
        &:before {
          content: "";
          display: flex;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: #dee3ea;
          margin-right: 10px;
          position: absolute;
          left: 4px;
          top: 5px;
        }
      }
      .payment-block__name {
        margin-bottom: 4px;
        display: inline;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        padding-left: 18px;
      }
      &.expired {
        border-left-color: #e85353;
      }
      &.payment {
    .payment-block__name {
      &:before {
        content: "";
        display: flex;
        margin-right: 10px;
        position: absolute;
        left: 0;
        top: 5px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 7px solid transparent;
      }
    }
    &.not_paid{
      .payment-block__name{
        &:before{
          border-top-color: #E85353;
        }
      }
    }
  }
  &.invoice {
    border-left-color: transparent;
    .payment-block__name {
      &:before {
        content: "";
        display: flex;
        //width: 12px;
        //height: 8px;
        margin-right: 10px;
        position: absolute;
        left: 0;
        top: 5px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 7px solid red;
        //transform: rotateX(180deg);
        // background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.43363 0H9.56637C10.79 0 11.4988 1.38624 10.7824 2.37824L7.21602 7.31628C6.61728 8.1453 5.38272 8.1453 4.78398 7.31628L1.21762 2.37824C0.501177 1.38625 1.20998 0 2.43363 0Z' fill='%239AA3B0'/%3E%3C/svg%3E%0A");
      }
    }
    &.not_paid{
      .payment-block__name{
        &:before{
          border-bottom-color: #0FA54E;
        }
      }
    }
  }
  &.paid {
    .payment-block__name{
      &:before{
        border-top-color: #9AA3B0;
        border-bottom-color: #9AA3B0;
      }
    }
  }
    }
  }
}
.hours-list {
  display: flex;
  flex-direction: column;
  position: relative;
  .time-line {
    position: absolute;
    width: calc(100% - 53px);
    border: 1px solid var(--color-red);
    right: 0;
    z-index: 3;
    background: var(--color-red);
    &:before {
      content: "";
      display: flex;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: var(--color-red);
      position: absolute;
      margin-top: -6px;
      margin-left: -2px;
    }
  }
  .hour {
    width: 100%;
    height: 60px;
    display: flex;
    .left-col {
      width: 53px;
      min-width: 53px;
      border-right: 1px solid var(--color-card-border);
      padding: 6px 8px;
    }
    .right-col {
      flex-grow: 1;
      border-bottom: 1px solid var(--color-card-border);
      position: relative;
      &:before {
        content: attr(data-num) ":00";
        font-size: 10px;
        position: absolute;
        color: var(--color-gray-text);
        left: -30px;
        bottom: -4px;
      }
      &.hideNum {
        border-bottom: none;
        &:before {
          content: "";
          display: none;
        }
      }
    }
  }
  .events-wrapper {
    position: absolute;
    width: calc(100% - 53px);
    right: 0;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-between;
    .event-block {
      position: absolute;
      flex-grow: 1;
      background: #e5dbff;
      border: 1px solid #fff;
      z-index: 2;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 3px 9px;
      overflow: hidden;
      &.flex-row {
        flex-direction: row;
        padding-top: 0;
        padding-bottom: 0;
        align-items: center;

        p {
          margin: 0;
          margin-right: 4px;
          font-size: 11px !important;
        }
      }
      p {
        color: #3d2e67;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px !important;
        line-height: 100% !important;
      }
    }
  }
}
.tasks-wrapper {
  .tasks-wrapper__heading {
    height: 32px;
    background: #ffedc3;
    border: 1px solid #fff;
    padding: 4px 9px;
    display: flex;
    color: #785400;
    align-items: center;
    .arrow {
      width: 18px;
      display: flex;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .tasks-wrapper__content {
    height: 0;
    overflow: hidden;
  }
  .tasks-wrapper__heading.showTasks + .tasks-wrapper__content {
    height: auto;
  }
}
.payments-wrapper {
  .payments-wrapper__heading {
    height: 32px;
    background: #f4f6f9;
    border: 1px solid #fff;
    padding: 4px 9px;
    display: flex;
    color: #785400;
    align-items: center;
    .arrow {
      width: 18px;
      display: flex;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .payments-wrapper__content {
    height: 0;
    overflow: hidden;
  }
  .payments-wrapper__heading.showPayments + .payments-wrapper__content {
    height: auto;
  }
}
</style>