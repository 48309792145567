var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"days-table"},[_c('div',{staticClass:"days-table__heading"},[_c('div',{staticClass:"left-col"},[_c('span',{staticClass:"date fz12 fw500 text-gray-text"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.date)))])]),_c('div',{staticClass:"right-col"},[(
          _vm.taskstsLoaded &&
          _vm.tasks.length != 0 &&
          _vm.categoriesFilters.tasks &&
          _vm.getPermissionsByType('can_show_tasks_in_the_calendar')
        )?_c('div',{staticClass:"tasks-wrapper"},[_c('div',{staticClass:"tasks-wrapper__heading fz13 fw500",class:{ showTasks: _vm.showAllTasks },on:{"click":function($event){_vm.showAllTasks = !_vm.showAllTasks}}},[(_vm.showAllTasks)?_c('span',{staticClass:"arrow"},[_c('svg',{attrs:{"width":"8","height":"5","viewBox":"0 0 8 5","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7.27557 4.00288C7.54284 4.00288 7.67669 3.67974 7.4877 3.49075L4.21196 0.215017C4.0948 0.0978596 3.90485 0.0978593 3.7877 0.215017L0.511973 3.49075C0.322984 3.67974 0.456834 4.00288 0.724105 4.00288L7.27557 4.00288Z","fill":"#2C2C2C"}})])]):_c('span',{staticClass:"arrow"},[_c('svg',{attrs:{"width":"8","height":"5","viewBox":"0 0 8 5","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0.764957 0.697845C0.497685 0.697845 0.363835 1.02099 0.552825 1.20998L3.82857 4.48571C3.94573 4.60287 4.13568 4.60287 4.25283 4.48571L7.52856 1.20998C7.71755 1.02099 7.5837 0.697845 7.31643 0.697845H0.764957Z","fill":"#2C2C2C"}})])]),_vm._v(" Tasks ("+_vm._s(_vm.tasks.length)+") ")]),_c('div',{staticClass:"tasks-wrapper__content"},_vm._l((_vm.tasks),function(task,index){return _c('div',{key:index,staticClass:"task-block",on:{"click":function($event){return _vm.$refs.taskInfoModal.showModal(task)}}},[_c('p',{staticClass:"task-block__title fz13 fw500"},[_vm._v(_vm._s(task.name))]),_c('p',{staticClass:"task-block__name fz13 no-plus"},[_vm._v(" "+_vm._s(_vm.getExecutors(task))+" ")]),_c('p',{staticClass:"task-block__prioity fz13"},[_vm._v(" Prioity: "),_c('span',{staticClass:"prioity",class:{
                  Urgent: task.priority == 1,
                  high: task.priority == 2,
                  Normal: task.priority == 3,
                  Low: task.priority == 4,
                  Low: task.priority == 5,
                }},[_c('svg',{attrs:{"width":"10","height":"12","viewBox":"0 0 10 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9.85528 1.28944C9.92177 1.15646 9.82507 1 9.67639 1H1.5V0.75C1.5 0.335786 1.16421 0 0.75 0C0.335786 0 0 0.335786 0 0.75V11.25C0 11.6642 0.335786 12 0.75 12C1.16421 12 1.5 11.6642 1.5 11.25V9H9.67639C9.82507 9 9.92177 8.84354 9.85528 8.71056L8 5L9.85528 1.28944Z","fill":"#FF9035"}})]),(task.priority == 1)?_c('span',[_vm._v("Urgent")]):_vm._e(),(task.priority == 2)?_c('span',[_vm._v("high")]):_vm._e(),(task.priority == 3)?_c('span',[_vm._v("Normal")]):_vm._e(),(task.priority == 4)?_c('span',[_vm._v("Low")]):_vm._e(),(task.priority == 5)?_c('span',[_vm._v("Low")]):_vm._e()])])])}),0)]):_vm._e(),(
          _vm.paymentsLoaded &&
          _vm.payments.results.length != 0 &&
          _vm.categoriesFilters.payments &&
          _vm.getPermissionsByType('can_show_payments_in_the_calendar')
        )?_c('div',{staticClass:"payments-wrapper"},[_c('div',{staticClass:"payments-wrapper__heading fz13 fw500",class:{ showPayments: _vm.showAllPayments },on:{"click":function($event){_vm.showAllPayments = !_vm.showAllPayments}}},[(_vm.showAllPayments)?_c('span',{staticClass:"arrow"},[_c('svg',{attrs:{"width":"8","height":"5","viewBox":"0 0 8 5","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7.27557 4.00288C7.54284 4.00288 7.67669 3.67974 7.4877 3.49075L4.21196 0.215017C4.0948 0.0978596 3.90485 0.0978593 3.7877 0.215017L0.511973 3.49075C0.322984 3.67974 0.456834 4.00288 0.724105 4.00288L7.27557 4.00288Z","fill":"#2C2C2C"}})])]):_c('span',{staticClass:"arrow"},[_c('svg',{attrs:{"width":"8","height":"5","viewBox":"0 0 8 5","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0.764957 0.697845C0.497685 0.697845 0.363835 1.02099 0.552825 1.20998L3.82857 4.48571C3.94573 4.60287 4.13568 4.60287 4.25283 4.48571L7.52856 1.20998C7.71755 1.02099 7.5837 0.697845 7.31643 0.697845H0.764957Z","fill":"#2C2C2C"}})])]),_vm._v(" Payments ("+_vm._s(_vm.payments.results.length)+") ")]),_c('div',{staticClass:"payments-wrapper__content"},_vm._l((_vm.payments.results),function(payment,index){return _c('div',{key:index,staticClass:"payment-block",class:{
              expired: _vm.getExpiredPayment(payment),
              payment: payment.payment_type == 'expense',
              invoice: payment.payment_type == 'income',
              paid: payment.status == 'paid',
              not_paid: payment.status == 'not_paid',
            },on:{"click":function($event){return _vm.$router.push(("/payment/list/detail/" + (payment.id)))}}},[_c('div',{staticClass:"payment-block__name fz13"},[_c('span',[_vm._v(_vm._s(payment.id)+": ")]),_c('span',[_vm._v(_vm._s(payment.account_name || "-"))])]),_c('p',{staticClass:"payment-block__status fz13"},[_vm._v(" "+_vm._s(payment.client_name || "-")+" ")]),_c('p',{staticClass:"payment-block__num fz13 fw500"},[_vm._v(" "+_vm._s(payment.payment_amount)+" "+_vm._s(payment.currency)+" ")])])}),0)]):_vm._e()])]),_c('div',{staticClass:"hours-list"},[(_vm.timeLineIsVisible)?_c('div',{staticClass:"time-line",style:({ top: _vm.time + 'px' })}):_vm._e(),_vm._l((24),function(hour){return _c('div',{key:hour,staticClass:"hour"},[_c('div',{staticClass:"left-col"}),_c('div',{staticClass:"right-col",class:{ hideNum: hour == 24 },attrs:{"data-num":hour}})])}),(_vm.eventsLoaded && _vm.categoriesFilters.events)?_c('div',{staticClass:"events-wrapper"},_vm._l((_vm.getEventsStyled),function(event,index){return _c('div',{key:index},[_c('div',{staticClass:"event-block",class:{ 'flex-row': event.height <= 30 },style:({
            top: ((event.top) + "px"),
            height: ((event.height) + "px"),
            width: ((100 / _vm.getEventsStyled.length) + "%"),
            left: (((100 / _vm.getEventsStyled.length)*index) + "%") || null,
          }),attrs:{"title":_vm._f("getTitle")(event)},on:{"click":function($event){return _vm.$refs.eventInfoModal.showModal(event, {date: _vm.date})}}},[_c('p',{staticClass:"fz13 fw500"},[_vm._v(_vm._s(event.name))]),(event.creatorInfo)?_c('p',{staticClass:"fz13"},[_vm._v(" "+_vm._s(event.creatorInfo.first_name || "")+" "+_vm._s(event.creatorInfo.last_name || "")+" ")]):_vm._e(),_c('p',{staticClass:"fz13"},[_vm._v(_vm._s(_vm._f("formatTime")(event.from_time))+" - "+_vm._s(_vm._f("formatTime")(event.to_time)))])])])}),0):_vm._e()],2),_c('taskInfoModal',{ref:"taskInfoModal"}),_c('eventInfoModal',{ref:"eventInfoModal",on:{"delEvent":function($event){return _vm.deleteEvent($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }